import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import find from 'lodash/find';
import some from 'lodash/some';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { EditButton, TopToolbar, useNotify, useRefresh } from 'react-admin';
import approveEvent from '../../api/approveEvent';
import cloneEvent from '../../api/cloneEvent';
import deleteEventScheduledConfig from '../../api/deleteEventScheduledConfig';
import getEventScheduledConfig, { EventScheduledConfig } from '../../api/getEventScheduledConfig';
import setEventScheduledConfig, { SetEventScheduledConfigType } from '../../api/setEventScheduledConfig';

const getScheduledConfigEvent = (configs: EventScheduledConfig[] | null, type: SetEventScheduledConfigType): EventScheduledConfig | undefined => {
  if (!configs || configs.length === 0) return undefined;
  const config = find(configs, config => {
    return config.type === type;
  })
  return config;
}
const isScheduledConfigEvent = (configs: EventScheduledConfig[] | null, type: SetEventScheduledConfigType) => {
  if (!configs || configs.length === 0) return false;
  return some(configs, config => {
    return config.type === type
    // if (config.type !== type) return false;
    // return moment().isSameOrAfter(moment(config.start_date)) && moment().isSameOrBefore(moment(config.end_date));
  })
}

const useStyles = makeStyles((theme) => ({
  actionButtonContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));


const EventShowActions = ({ basePath, data, resource }: any) => {
  const { uid, status, platform_source, start_datetime, end_datetime, language } = data || {};
  const showApproveButton = platform_source === 'Playwhat' && status === 'pending';
  const classes = useStyles();

  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledConfigs, setScheduledConfigs] = useState<EventScheduledConfig[] | null>(null);
  
  const getScheduledConfigs = useCallback(async () => {
    const configs = await getEventScheduledConfig(uid);
    setScheduledConfigs(configs);
  }, [uid])
  const onApproveButtonClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await approveEvent(uid);
      const { approvalResult, notifyResult } = res;
      notify(`Updated? ${approvalResult} | Notified? ${notifyResult}`);
      refresh();
    } catch (err: any) {
      console.error(err);
      notify(`Error: ${err.message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  }, [uid, notify, refresh])
  const onCloneClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await cloneEvent(uid);
      notify(`Cloned? ${res !== null}`);
    } catch (err: any) {
      console.error(err);
      notify(`Error: ${err.message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  }, [uid, notify, refresh]);
  const showEventScheduledConfigBtn = status === 'approved' && moment(end_datetime).isSameOrAfter(moment()) && scheduledConfigs !== null;
  const isRecommendedEvent = isScheduledConfigEvent(scheduledConfigs, 'RECOMMENDED_EVENT');
  const isExclusiveEvent = isScheduledConfigEvent(scheduledConfigs, 'EXCLUSIVE_EVENT');
  const isHeroEvent = isScheduledConfigEvent(scheduledConfigs, 'HERO_EVENT');
  
  const onSetRecommendedEvent = useCallback(async (type: SetEventScheduledConfigType, isUnmark?: boolean) => {
    try {
      setIsLoading(true);
      const config = getScheduledConfigEvent(scheduledConfigs, type);
      const start_date = moment(start_datetime).subtract(14, 'days').toISOString();
      const end_date = moment(end_datetime).subtract(1, 'days').toISOString();
      let res: any = null;
      if (isUnmark) {
        if (!config?.uid) throw new Error('Cannot find scheduled config');
        res = await deleteEventScheduledConfig(config.uid);
      } else {
        res = await setEventScheduledConfig({
          uid: config?.uid,
          priority: 50,
          start_date,
          end_date,
          type,
          language,
          target_uid: uid,
        });
      }
      const isSuccessful = res !== null;
      notify(`Create/Update ScheduledConfig? ${isSuccessful}`);
      if (isSuccessful) await getScheduledConfigs();
    } catch (err: any) {
      console.error(err);
      notify(`Error: ${err.message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  }, [uid, scheduledConfigs, start_datetime, end_datetime, language, getScheduledConfigs, notify]);

  useEffect(() => {
    getScheduledConfigs();
    return () => {
      setScheduledConfigs(null);
    }
  }, [uid])

  return (
    <TopToolbar className={classes.actionButtonContainer}>
      <ButtonGroup disableElevation variant="contained">
        <EditButton basePath={basePath} record={data} label='Edit Admin-Only Fields' disabled={isLoading} />
        {/* Add your custom actions */}
        <Button variant="contained" color="primary" startIcon={<FileCopyIcon />} onClick={onCloneClick} disabled={isLoading}>Clone it!</Button>
        {showApproveButton && <Button variant="contained" color="secondary" startIcon={<ThumbUpIcon />} onClick={onApproveButtonClick} disabled={isLoading}>Approve n Notify</Button>}
        {showEventScheduledConfigBtn &&
          <Button variant="contained" color="secondary" onClick={() => onSetRecommendedEvent('RECOMMENDED_EVENT', isRecommendedEvent)} disabled={isLoading}>{isRecommendedEvent ? 'Unmark Recommended Event' : 'Mark as Recommended Event'}</Button>}
        {showEventScheduledConfigBtn &&
          <Button variant="contained" color="secondary" onClick={() => onSetRecommendedEvent('HERO_EVENT', isHeroEvent)} disabled={isLoading}>{isHeroEvent ? 'Unmark Hero Event' : 'Mark as Hero Event'}</Button>}
        {showEventScheduledConfigBtn &&
          <Button variant="contained" color="secondary" onClick={() => onSetRecommendedEvent('EXCLUSIVE_EVENT', isExclusiveEvent)} disabled={isLoading}>{isExclusiveEvent ? 'Unmark Exclusive Event' : 'Mark as Exclusive Event'}</Button>}
      </ButtonGroup>
    </TopToolbar>
  )
};
export default EventShowActions;