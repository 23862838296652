import moment from 'moment';
import React from 'react';
import { BooleanInput, Edit, FormTab, NumberField, NumberInput, required, SelectInput, TabbedForm, TextField, TextInput, DateField, DateTimeInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Link } from "react-router-dom";
import { statusList } from './constant';
import EventTagsEdit from './EventTagsEdit';
import { platformList, langList, eventFrequencyTypeList , eventFrequencySettingList, targetAudiencesList, enrolTypeList} from '../event/constant';

const EventTitle = ({ record }) => {
  return <span>Event | {record ? `${record.title}` : ''}</span>;
};

const ScoreBreakDownField = ({ record = {} }) => <div>{(record.scoreBreakdown || '').split('\n').map(val => <p>{val}</p>)}</div>;

const Aside = ({ record }) => {
  return (
    <div style={{ width: 400, margin: '1em' }}>
      <h6>Instruction</h6>
      <div>
        Posts will only be published once an editor approves them
      </div>
      {record && (
        <>
          <p>ID: <b>{record.uid}</b></p>

          {record.authorId && <p><Link to={`/users/${record.authorId}`}>View author</Link></p>}

          <div>
            <small>Last Updated: {moment(record.updated_datetime).format()}</small><br />
            <small>Created: {moment(record.created_datetime).format()}</small>
          </div>
        </>
      )}
    </div>
  )
};

export function EventEdit(props) {
  return (
    <Edit title={<EventTitle />} submitOnEnter={false} {...props}>
      <TabbedForm>
        <FormTab label="Admin Setting">
          <BooleanInput label="Disallow sever to update this event?" source="disallowRobotUpdate" defaultValue={false} />
          <TextField source="lastUpdatedBy"  />
          <SelectInput source="status" validate={[required()]} choices={statusList} />
          <TextInput source="reject_reason" fullWidth multiline minRows={2} />
          <NumberInput source="staffScoreAdjustment" min={-100} max={100} step={1} defaultValue={0} />
          <NumberField source="score" fullWidth helperText="score breakdown refer to https://playwhat.atlassian.net/secure/RapidBoard.jspa?rapidView=1&projectKey=PW&modal=detail&selectedIssue=PW-13" />
          <ScoreBreakDownField multiline fullWidth source="scoreBreakdown" />
        </FormTab>
         <FormTab label="basic">
          <TextField source="status" />
          <TextField label="Id" source="id" />
          <TextInput fullWidth source="slug" helperText="user-friendly ID (shown in the URL)" />
          <TextInput fullWidth source="title" />
          <RichTextInput multiline fullWidth source="excerpt" minRows={2} />
          <RichTextInput multiline fullWidth source="desc" minRows={5} />
          <TextInput fullWidth source="logo_url" type="url" />
          <TextInput multiline fullWidth source="gallery_images" minRows={3} helperText="Comma-separated Image URLs" />
          <NumberInput source="price" />
          <TextInput source="ticketingInformation" fullWidth multiline minRows={2} />
          <TextInput source="currency" helperText="All capital letters, e.g. HKD" />
          <TextInput fullWidth source="event_url" type="url" />
          <SelectInput source="platform_source" choices={platformList} />
          <SelectInput source="language" choices={langList} />
          <DateField source="updated_datetime" showTime />
          <DateField source="created_datetime" showTime />
        </FormTab> 
        <FormTab label="categories">
          <EventTagsEdit />
        </FormTab>
        <FormTab label="date">
          <DateTimeInput source="start_datetime" />
          <DateTimeInput source="end_datetime" />
          <TextField source="eventRecurrence" />
          <TextField source="datetime_string" />
          <TextField multiline source="datetime_array" />
          <SelectInput source="eventFrequencyType" choices={eventFrequencyTypeList} />
          <SelectInput source="eventFrequencySetting" choices={eventFrequencySettingList} />
          <NumberInput source="eventFrequencySettingOccurence" placeholder={1} fullWidth />
          <TextInput source="eventFrequencySettingOccurenceOptions" helperText="Comma-separated number" fullWidth />
          <TextInput source="dateSupportInformation" fullWidth multiline minRows={2} />
        </FormTab>
        
        <FormTab label="other">
          <TextInput source="hashtags" fullWidth helperText="{official hashtag};{other hashtags separated by comma}, e.g. Family;Weekend,Sha Tin,Tea Time" />
          <TextInput source="targetAudiences" fullWidth helperText={`Comma-separated string, e.g. ${targetAudiencesList.map(t => t.id).join(',')}`} />
          <hr></hr>
          <SelectInput source="enrol_type" choices={enrolTypeList} />
          <DateTimeInput source="enrol_start_date" />
          <DateTimeInput source="enrol_end_date" />
          <TextInput source="enrol_link" type="url" fullWidth />
          <hr></hr>
          <TextInput multiline source="fbLinks" fullWidth helperText="Comma-separated URLs" />
          <TextInput multiline source="youtubeLinks" fullWidth helperText="Comma-separated URLs" />
          <TextInput multiline source="igLinks" fullWidth helperText="Comma-separated URLs" />
          <hr></hr>
          <TextInput multiline source="promotion" fullWidth />
        </FormTab> 
        <FormTab label="relationship">
          <TextInput source="organizer_uid" fullWidth />
          <TextInput source="venue_uid" fullWidth />
          <TextInput source="parentUid" fullWidth />
          <TextInput source="authorId" fullWidth />
        </FormTab> 
      </TabbedForm>
    </Edit>
  )
}